import { Container, Row, Col } from 'react-bootstrap';
import { useState } from "react";

const Banner = () => {
  const [images] = useState(
    Array.from({ length: 41 }, (_, i) => ({
      original: `${i + 1}.jpg`,
      thumbnail: `${i + 1}.jpg`,
    }))
  );

  return (
    <>
      <div className='banner memes'>
        <Container>
          <Row>
            <h3>Library of Memes</h3>
            <div className="image-grid">
              {images.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image.original} alt={`Meme ${index + 1}`} />
                </div>
              ))}
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Banner;

import { Container, Row, Col, Image } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';

const MC = () => {
  // get data.usd_market_cap from https://frontend-api.pump.fun/coins/C1aUK5VpDoEKqsvDpc7gQf1HVdgbrw3kwXE7KEbJpump
  // if it's in millions then round it to two decimal places, ie. 1.56 and put $ in front and M behind, ie. $1.56M then put in ${marketcap}
  // if no data then just put '-'

  // get data.liquidity from https://app.meteora.ag/clmm-api/pair/AmKSPTnD9WgZKzRkb9zV8AkVtWy44HDzQsXb6TS8PAwc
  // round it to thousands, ie $76k then put in ${TVL}
    // if no data then just put '-'



  return (
    <>
      <div className='banner mc'>
        <Container>
            <Row>
            <h3>who is peagle?</h3>
            <p>The official memecoin for Patriot Eagle🦅🇺🇸 on Solana. Inspired by PEPE & Matt Furie<br/><br/>Make Crypto Great Again and Vote For $PEAGLE 2024 🗳️</p>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default MC

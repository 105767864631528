import { Container, Row, Col, Image, Nav } from 'react-bootstrap'
import 'react-vertical-timeline-component/style.min.css';
import { LinkContainer } from 'react-router-bootstrap';
import styled from "styled-components";
import 'doodle.css/doodle.css'


export const StyledButton = styled.button`
background-color: transparent;
color: #000;
padding: 0.7rem 2rem;
padding-bottom: 0.9rem;
letter-spacing: 2px;
font-family: 'Obviously-Bold', Roboto, sans-serif;
font-weight: 700;
font-size: 1.2rem;
margin-top: 10px;
border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-image: url(/button-border.svg) 10 10 10 10 stretch stretch;
display: block;
    margin-left: 0;
    margin-right: auto;
  :active {
    
  }
  :hover {
    background-color: white;
    color: #000 !important;
    -webkit-transition: 300ms ease all;
    -o-transition: 300ms ease all;
    transition: 300ms ease all;
    text-shadow: none !important;
  }
  :disabled {
    opacity: 0.4
  }
`;

export const StyledRoundButton = styled.button`
background-color: #da1333;
width: 40px;
height: 40px;
box-shadow: none;
border-radius: 0px !important;
font-size: 1.4rem;
border: 3px solid #ed1b24;
color: #ffffff;
border-width: 1px;
font-family: 'Montserrat', Roboto, sans-serif;
font-weight: 800;

:hover {
  background-color: rgba(33,56,117,0.05);
  text-shadow: 0px 0px 5px rgb(33 56 117 / 25%);
-webkit-box-shadow: 0px 0px 5px 0px rgb(33 56 117 / 15%);
box-shadow: 0px 0px 5px 0px rgb(33 56 117 / 15%);
  border: 2px solid #da1333;
  -webkit-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  transition: 300ms ease all;
  color: #da1333;
}
`;

const Home = () => {


  // when clicking connect-button, open this in new tab: https://jup.ag/swap/SOL-C1aUK5VpDoEKqsvDpc7gQf1HVdgbrw3kwXE7KEbJpump
  const handleConnectButtonClick = (e) => {
    e.preventDefault();
    window.open('https://jup.ag/swap/SOL-C1aUK5VpDoEKqsvDpc7gQf1HVdgbrw3kwXE7KEbJpump', '_blank');
  };


  return (
    <div class="hero">

    <Container className="main">
    <Row>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
            <h2><span class="small">vote</span><br/>peagle</h2>
{/* 
                <StyledButton className="doodle-border connect-button" onClick={handleConnectButtonClick}>BUY $PEAGLE</StyledButton> */}
          

            </Col>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
            <img className="heroimg" src="peagle.png" alt="peagle"/>
          

            </Col>
          </Row>

    </Container>


    </div>
  )
}

export default Home
import { Container, Row, Col, Image } from 'react-bootstrap'

const Banner = () => {
  return (
    <>
      <div className='banner'>
        <Container>
            <Row>
            <h3>How can I support Peagle?</h3>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
            <ol>
            <li>Buy Solana on an exchange or in a wallet</li>
            <li>Create a <a href="https://phantom.app/" target='_blank'>Phantom wallet</a></li>
            <li>Send Solana to your Phantom wallet address</li>
            <li>Connect your Phantom wallet to <a href="https://jup.ag/swap/SOL-8zjGBNiwfSCntPLWL63S3pr3r9zvyLBXA6fjAyAPpump" target='_blank'>Jupiter</a> and trade Solana for $PEAGLE</li>
            <li>Make Crypto Great Again!</li>
</ol>
</Col>            
<Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }}>
<img className="buyimg" src="oval.png" alt="How to buy $PEAGLE"/>
</Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default Banner

import { Container, Row, Col, Image } from 'react-bootstrap'

const Footer = () => {
  return (
    <>
      <footer className='text-left'>
        <Container>
            <Row>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://x.com/PeagleOnSolana" target="_blank" rel="noreferrer"><img alt="twitter" src="twit2.png"></img></a></Col>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://t.me/PeaglePortal" target="_blank" rel="noreferrer"><img alt="telegram" src="tele2.png"></img></a></Col>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://dexscreener.com/solana/CPHcjesc8xtCiAEdRvB3wvEokfKZDHkwoKFd58eaJNxM" target="_blank" rel="noreferrer"><img alt="dexscreener" src="screen2.png"></img></a></Col>
            <Col xs={3} sm={3} className="mobile social-icons"><a href="https://www.dextools.io/app/en/solana/pair-explorer/CPHcjesc8xtCiAEdRvB3wvEokfKZDHkwoKFd58eaJNxM" target="_blank" rel="noreferrer"><img alt="dextools" src="tools2.png"></img></a></Col>
              
              <p class="contract-text center">&copy; PEAGLE, 2024. All rights reserved.<br/><br/>Disclaimer: $PEAGLE is a meme coin with no intrinsic value or expectation of financial return.<br/><br/>$PEAGLE is for entertainment purposes only.</p>
              {/* <p class="contract-text right"></p> */}
            </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer

import { Container } from 'react-bootstrap'
import { Route, Switch, Redirect } from 'react-router-dom'

import './style.css';

// Layout
import Layout from './layout/Layout'

// pages
import NewMintApp from './pages/NewMintApp'

const App = () => {
  return (
    <Layout>
      {/* <Container> */}
        <Switch>
          <Route path='/' component={NewMintApp} exact />
          <Redirect to="/" />
        </Switch>
      {/* </Container> */}
    </Layout>
  )
}

export default App

import CustomThemeProvider from "../components/CustomThemeProvider"
import Web3ModalProvider from "../components/Web3ModalProvider"
import Home from "./Home"

const NewMintApp = () => {

  return (
    <CustomThemeProvider>
        <Web3ModalProvider>
        <Home />
      </Web3ModalProvider> 
    </CustomThemeProvider>
  )
}

export default NewMintApp